import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import {
  AuthModule,
  CartModule,
  CatalogModule,
  CoreModule,
  ElasticsearchModule,
  ErrorModule,
  FactoryAttestationModule,
  FavoriteModule,
  InjectorClass,
  PageMetaModule,
  ParameterModule,
  SalesOfferModule,
  SalesOrderModule,
  SalesCreditNoteModule,
  SearchService,
  SentryModule,
  SteelModule,
  BacklogModule,
  FactoryAttestationService,
  SalesDocumentService,
  SalesDocumentModule,
  PickListModule,
  GoogleTagManagerAdapterModule,
} from '@lobos/library';
import { LOCALE_CURRENCY_MAPPING, TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { HookHandlerModule } from './hooks/hook-handler/hook-handler.module';
import { HookTriggerModule } from './hooks/hook-trigger/hook-trigger.module';
import { PipesModule } from './pipes/pipes.module';
import { CookieModule } from 'ngx-cookie';
import { Platform } from '@angular/cdk/platform';
import { GermanDateAdapter } from './services/date/german-date.adapter';
import { popperVariation, TippyModule, tooltipVariation } from '@ngneat/helipopper';
import { EhgFacetedSearchService } from './services/catalog/ehg-faceted-search.service';
import { TranslocoRootModule } from './transloco-root.module';
import { BrowserStateInterceptor } from './interceptors/browser-state-interceptor';
import { EhgBaseInterceptor } from './interceptors/ehg-base.interceptor';
import { EhgSearchService } from './services/catalog/ehg-search.service';
import { HandleErrorsInterceptor } from './interceptors/handle-errors.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { EhgFactoryAttestationService } from './services/factory-attestation/ehg-factory-attestation.service';
import { EhgSalesDocumentService } from './services/sales-document/sales-document.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BacklogModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    ErrorModule.forRoot({
      showOutput: !environment.production || environment.debugging,
      disableErrorHandler: environment.debugging,
    }),
    CoreModule.forRoot({
      baseUrl: environment.host,
      branchKey: environment.api.branchKey,
      webshopId: environment.api.webshopId,
    }),
    SentryModule.forRoot({
      active: environment.sentry.active,
      debug: environment.debugging,
      environment: environment.name,
      dsn: environment.sentry.dsn,
      release: environment.version,
    }),
    HookHandlerModule.forRoot(),
    ElasticsearchModule.forRoot({
      availableLanguages: environment.availableLang,
      defaultLanguage: environment.defaultLocale,
    }),
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AuthModule.forRoot({
      unauthorizedRoute: environment.auth.routes.login,
      authorizedRoute: environment.auth.routes.home,
      apiUrl: environment.apiUrl,
    }),
    AppRoutingModule,
    HttpClientModule,
    CartModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    FavoriteModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    SalesCreditNoteModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    TranslocoRootModule,
    TranslocoLocaleModule.forRoot({
      langToLocaleMapping: {
        en: 'en-US',
        de: 'de-AT',
      },
    }),
    TranslocoMessageFormatModule.forRoot(),
    ComponentsModule,
    PipesModule,
    PageMetaModule,
    SteelModule,
    ParameterModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    environment.tracking.gtm ? GoogleTagManagerAdapterModule.forRoot({ id: environment.tracking.gtm }) : [],
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    HookTriggerModule.forRoot(),
    CatalogModule.forRoot({
      apiUrl: environment.apiUrl,
      pageIndex: 0,
      pageSize: 500,
      sortBy: 'relevance',
      viewMode: 'grid',
    }),
    CookieModule.forRoot(),
    SalesOfferModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    SalesOrderModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
    FactoryAttestationModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    ToastrModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'loading...' }),
    SalesDocumentModule.forRoot({ apiUrl: environment.apiUrl }),
    PickListModule.forRoot({ apiUrl: environment.apiUrl }),
  ],
  providers: [
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: environment.debugging,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EhgBaseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleErrorsInterceptor,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        backdropClass: 'overlay',
      } as MatDialogConfig,
    },
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: environment.host + environment.apiUrl },
    },
    {
      provide: DateAdapter,
      useClass: GermanDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    EhgFacetedSearchService,
    {
      provide: SearchService,
      useClass: EhgSearchService,
    },
    {
      provide: FactoryAttestationService,
      useClass: EhgFactoryAttestationService,
    },
    {
      provide: SalesDocumentService,
      useClass: EhgSalesDocumentService,
    },
    {
      provide: LOCALE_CURRENCY_MAPPING,
      useValue: {
        'en-US': 'EUR',
        'de-AT': 'EUR',
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    InjectorClass.set(injector);
  }
}
